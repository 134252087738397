rodijco.controller 'CustomersController', [
  '$scope'
  '$routeParams'
  '$location'
  'httpService'
  (scope, routeParams, $location, httpService) ->

    scope.customers = {}
    scope.currentPage = if isNaN(routeParams.page) then 0 else routeParams.page
    scope.count = 0
    scope.limit = 10
    scope.totalPages = 0
    scope.showPages = 1
    scope.showHellip = false

    httpService.get "customer?page=#{scope.currentPage}", (response) ->
      if response.success
        scope.customers = response.data?.users
        scope.savedUsers = response.data?.users
        scope.count = response.data?.count

        rounded = Math.ceil(scope.count / scope.limit)
        rounded = if rounded > 0 then rounded - 1 else rounded
        scope.totalPages = [0..(rounded)]

        scope.showHellip = if rounded > (rounded - scope.showPages) then true else false

    scope.$watch 'search', ->
      unless scope.search and scope.search.length > 1
        scope.customers = scope.savedUsers
        return

      httpService.get "customer/search/#{scope.search}", (response) ->
        scope.customers = response.data?.results if response.success

    scope.removeCustomer = (customer) ->
      httpService.delete "customer/#{customer}", (response) ->
        if response.success
          location.reload()
        else
          console.error response

]
